import React from "react";
import { Grid, Button } from "semantic-ui-react";
import Helmet from "react-helmet";
import { Link } from "gatsby";
import {
  PageLayout,
  About,
  HeroElement,
  ImageCollection,
  Reviews
} from "@fishrmn/fishrmn-components";
import MessageBanner from "../components/MessageBanner/MessageBanner";

import Layout from "../components/Layout";

export default class extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <MessageBanner />
        <Layout className="home-container">
          <Helmet>
            <title>Thai Amarin | Home - Newton</title>
            <meta
              name="description"
              content="Thai Amarin of Newton offers up a clean, modern space for group dining, or intimate dining, as well as take-out and delivery to the surrounding areas."
            />
          </Helmet>
          <PageLayout
            hero={
              <HeroElement
                header={"Thai Cuisine in Newton, MA"}
                tagline={
                  <React.Fragment>
                    Thai restaurant in Newton Corner, MA with large open dining
                    room, <br />
                    free parking, group dining, take-out, delivery, catering.
                  </React.Fragment>
                }
                images={[
                  "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/IMG_9665+(1)+(1).jpg"
                ]}
                showMap={false}
                ctas={[
                  // { text: "Lunch", link: "/lunch-menu/", internal: true },
                  // { text: "Dinner", link: "/dinner-menu/", internal: true },
                  {
                    text: "Order Online",
                    link: "https://ordering.chownow.com/order/10080/locations",
                    internal: false
                  }
                ]}
                ctaColor={"white"}
                ctaInverted={true}
                containerColor={"primary"}
                textColor={"white"}
                showMultiple={true}
                overlay={true}
                fullWidth={true}
                parallax={true}
                containerAs={"solid"}
                height={"120vh"}
                gutter={false}
              />
            }
            subfooter={false}
          >
            <Grid
              stackable
              className="component-section-container"
              verticalAlign={"middle"}
              textAlign={"center"}
            >
              <Grid.Column width={8}>
                <About
                  header="A Destination"
                  content={
                    "Thai Amarin of Newton offers up a clean, modern space for group dining, or intimate dining, as well as take-out and delivery to the surrounding area. There's a free parking garage for customers, and plenty of parking in the city lot next door. Come visit us today and satisfy your love of Thai Food!"
                  }
                />
              </Grid.Column>
            </Grid>
            <Grid
              stackable
              className="component-section-container"
              verticalAlign={"middle"}
              textAlign={"center"}
            >
              <Grid.Column width={16}>
                <ImageCollection
                  as={"gallery"}
                  centered={true}
                  cutUnevenRows={true}
                  disable={"none"}
                  header={"Gallery"}
                  images={[
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/IMG_9665.jpg",
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/FullSizeRender.jpg",
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/IMG_6899.jpg",
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/FullSizeRender_2.jpg"
                  ]}
                  numberOfRowDisplayItems={3}
                />
              </Grid.Column>
              <Grid.Column width={16}>
                <Link to="/gallery/">
                  <Button primary>See More</Button>
                </Link>
              </Grid.Column>
            </Grid>
            <Grid
              stackable
              className="component-section-container"
              verticalAlign={"middle"}
              textAlign={"left"}
            >
              <Grid.Column width={16}>
                <Reviews
                  reviews={[
                    {
                      name: "Chinazam Madu",
                      content:
                        "The food is really good and perfect for the price. The actual restaurant itself looks very classy and original and the staff are some of the nicest people ever! We did notice thought that if you buy more than about $25 of food they add an automatic tip to your bill. Overall preety good restaurant for a date or birthday",
                      link: null
                    },
                    {
                      name: "Sara Lauter",
                      content:
                        "I love this place! The food is delicious and of excellent quality. You can't go wrong with any of the curries. Also, spicy actually means spicy! One of the only places I go to where the spiciest level is too much for me. The crab rangoons actually taste like crab and the Golden Bags are another of my favorite appetizers. Great spot for a date night but also awesome for a work lunch pick-up!",
                      link: null
                    },
                    {
                      name: "Natasha Blaskovich",
                      content:
                        "The best Thai I have ever had! Great selection, there are tables where you can sit on pillows on the ground in a more traditional style. I recommend the satay chicken skewers and the green curry! Great family place. Free parking is a plus behind the building!",
                      link: null
                    },
                    {
                      name: "vms reception",
                      content:
                        "I love the food. Great Staff and Wonderful service! \n\n10/10 Would Recommend",
                      link: null
                    },
                    {
                      name: "Jessica in your eyes Moore",
                      content:
                        "Very nice people. Very good food. Pleasant experience. I highly reccomend this restaurant. It's location is disastrous to look at but once inside, you're home",
                      link: null
                    }
                  ]}
                  as={"verticalItem"}
                  buttonBasic={false}
                  buttonSize={"medium"}
                  cardInlinePhoto={"left"}
                  centerHeader={false}
                  header={"Reviews"}
                  imageCircular={false}
                  imageSize={"small"}
                  itemButtonFloat={"left"}
                  itemCenterContent={false}
                  itemsDivided={false}
                  itemsPerRow={3}
                  linkAs={"none"}
                  onlyEvenRows={true}
                  showImage={true}
                  verticalItemAlign={"center"}
                />
              </Grid.Column>
            </Grid>
          </PageLayout>
        </Layout>
      </React.Fragment>
    );
  }
}
